import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../../model/app-state.model';
import { IError } from '../../../model/error.model';
import Request from '../../../utils/Request';
import { LogoutState, LogoutUrl } from '../constants';
import { showToast } from '../../../common/Toast/slice/toastSlice';
import { IToastType } from '../../../common/Toast/constants';


const initialState: LogoutState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const logout = createAsyncThunk<
  string,
  void,
  { state: AppState, rejectValue: IError }
>('auth/logout', async (_, { dispatch, rejectWithValue }) => {
  return new Promise<string>((resolve, reject) => {
    try {
      const successFn = (data: string, headers, isSuccess, status) =>
        resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      api.get(LogoutUrl.LOGOUT);
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = logoutSlice.actions;
export default logoutSlice.reducer;