import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../model/app-state.model";
import { IError } from "../../../../model/error.model";
import Request from "../../../../utils/Request";
import {
  CustomerUrl,
  CustomerLocationInsListPageable,
  CustomerLocationInsListState,
} from "../constants";
import { showToast } from "../../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../../common/Toast/constants";
import { IParamsPageRequest } from "../../../../model/page.model";

const initialState: CustomerLocationInsListState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const customerLocationInsList = createAsyncThunk<
  CustomerLocationInsListPageable,
  IParamsPageRequest,
  { state: AppState; rejectValue: IError }
>("customerLocationInsList", async (params, { dispatch, rejectWithValue }) => {
  return new Promise<CustomerLocationInsListPageable>((resolve, reject) => {
    try {
      const successFn = (data: CustomerLocationInsListPageable, headers, isSuccess, status) =>
        resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      const url = CustomerUrl.CUSTOMER_LOCATION_INS_LIST.replace(":customerId", `${params?.routeId}`);
      api.get(url, {...params});
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const customerLocationInsListSlice = createSlice({
  name: "customerLocationInsList",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerLocationInsList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        customerLocationInsList.fulfilled,
        (state, action: PayloadAction<CustomerLocationInsListPageable>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(customerLocationInsList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = customerLocationInsListSlice.actions;
export default customerLocationInsListSlice.reducer;
