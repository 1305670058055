import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../model/app-state.model";
import { IError } from "../../../../model/error.model";
import Request from "../../../../utils/Request";
import {
  IWorkOrderResponse,
  IWorkOrderRequest,
  WorkOrderUrl,
  WorkOrderState,
} from "../constants";
import { showToast } from "../../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../../common/Toast/constants";

const initialState: WorkOrderState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const workOrderUpdate = createAsyncThunk<
  IWorkOrderResponse,
  IWorkOrderRequest,
  { state: AppState; rejectValue: IError }
>("workOrderUpdate", async (workOrderRequest, { dispatch, rejectWithValue }) => {
  return new Promise<IWorkOrderResponse>((resolve, reject) => {
    try {
      const successFn = (data: IWorkOrderResponse, headers, isSuccess, status) =>
        resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      if (workOrderRequest.id) {
        const url = WorkOrderUrl.BY_ID.replace(":workOrderId", workOrderRequest.id);
        api.put(url, workOrderRequest);
      } else {
        api.post(WorkOrderUrl.LIST, workOrderRequest);
      }
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const workOrderUpdateSlice = createSlice({
  name: "workOrderUpdate",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(workOrderUpdate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        workOrderUpdate.fulfilled,
        (state, action: PayloadAction<IWorkOrderResponse>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(workOrderUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = workOrderUpdateSlice.actions;
export default workOrderUpdateSlice.reducer;
