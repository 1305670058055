import { UUID } from "crypto";
import { IAuditable } from "../../../model/auditable.model";
import { IError } from "../../../model/error.model";
import { IPageableResponse } from "../../../model/page.model";
import { IGetRequest } from "../../../model/request.model";
import { IConsumableResponse } from "../../MasterData/Consumable/constants";
import { ICustomerResponse } from "../../MasterData/Customer/constants";
import { IEmployeeResponse } from "../../MasterData/Employee/constants";
import { IProductResponse } from "../../MasterData/Product/constants";
import { Organization } from "../../UsersAndRoles/constants";
import { IWorkOrderItem, IWorkOrderItemConsumable } from "../WorkOrder/constants";

export interface IInvoiceResponse extends IAuditable {
  id: number;
  customer: ICustomerResponse;
  organization: Organization;
  employee: IEmployeeResponse;
  doi: string;
  customerOrderDate: string;
  version: number;
  notes: string;
  topic: string;
  status: InvoiceStatus;
  invoiceItems: IInvoiceItem[];
  invoiceItemConsumables: IInvoiceItemConsumable[];
  customerOrderNumber: string;
  code: string;
  paymentTerms: string;
}

export interface IInvoiceItem extends IAuditable {
  id: number;
  product: IProductResponse;
  quantity: number;
  pricePerUnit: number;
  priceTotal: number;
  workOrderItem: IWorkOrderItem;
  tax: ITaxResponse;
  version: number;
  isActive: boolean;
  workOrderId?: number;
  workOrderName?: string;
}

export interface IInvoiceItemConsumable extends IAuditable {
  id: number;
  consumable: IConsumableResponse;
  quantity: number;
  pricePerUnit: number;
  priceTotal: number;
  workOrderItemConsumable: IWorkOrderItemConsumable;
  tax: ITaxResponse;
  version: number;
  isActive: boolean;
  workOrderId?: UUID;
  workOrderName?: string;
  consumableId: UUID;
  consumableName: string;
}

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  SENT = "SENT",
  REJECTED = "REJECTED",
  PARTIAL_PAID = "PARTIAL_PAID",
  PAID = "PAID",
}
export interface InvoiceListState {
  isLoading: boolean;
  data: InvoiceListPageable | null;
  error: IError | null;
}

export interface InvoiceListPageable extends IPageableResponse {
  content: IInvoiceResponse[];
}

export interface IInvoiceRequest extends IGetRequest {
  id: string;
}

export interface ITaxResponse extends IAuditable{
  id: string;
  name: string;
  description: string;
  percentage: number;
}

export interface InvoiceTaxListPageable extends IPageableResponse{
  content: ITaxResponse[];
}

export interface InvoiceState {
  isLoading: boolean;
  data: IInvoiceResponse | null;
  error: IError | null;
}

export interface InvoiceTaxListState {
  isLoading: boolean;
  data: InvoiceTaxListPageable | null;
  error: IError | null;
}

export enum InvoiceUrl {
  LIST = "/invoices",
  BY_ID = "/invoices/:invoiceId",
  LIST_TAX = "/invoices/taxes"
}
