import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../common/AuthProvider/components/AuthProvider";
import Layout from "../common/Layout";
import { RoutePath } from "./constants";
import { getTenantAwarePath } from "../utils/common";

interface IProps {
  path: RoutePath;
}

const ProtectedLayout = ({
  ...otherProps
}: IProps): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userDetails, validateUser, resetGetCurrentUser } =
    useContext(AuthContext);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && validateUser) {
      setIsLoading(true);
      validateUser({});
    }
    return () => {
      if (resetGetCurrentUser) {
        resetGetCurrentUser();
      }
    };
  }, []);

  useEffect(() => {
    // Scroll to top on page change
    window.scroll(0, 0);
  }, [otherProps.path]);

  useEffect(() => {
    if (!userDetails.isLoading && userDetails.data) {
      setIsLoading(false);
      setIsAuthenticated(!!userDetails.data);
      if (location.pathname === "/" || location.pathname === "/login") {
        navigate(getTenantAwarePath(otherProps.path, userDetails?.data?.organizationCode));
      }
    } else if (!userDetails.isLoading && userDetails.error) {
      setIsLoading(false);
      setIsAuthenticated(false);
      navigate(RoutePath.LOGIN);
    }
  }, [userDetails]);

  if (isLoading) {
    return <></>;
  }

  return isAuthenticated ? (
    <Layout userDetails={userDetails}/>
  ) : (
    <Navigate to={RoutePath.LOGIN} />
  );
};

export default ProtectedLayout;
