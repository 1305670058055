import React, { useState } from "react";
import { Layout, Menu } from "antd";
import mispahLogo from "../../../assets/images/Mispah.png";
import {
  AuditOutlined,
  CalendarOutlined,
  ContactsOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  FileSyncOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  ProductOutlined,
  SafetyOutlined,
  SolutionOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/constants";
import { IStateType } from "../../../model/app-state.model";
import { getTenantAwarePath } from "../../../utils/common";
import { FlexContainerSider, FlexShrink, ImageContainer, StyledImage } from "../styles";

const { Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

export interface ISiderProps {
  userDetails: IStateType;
}

const SiderComponent = ({ userDetails }: ISiderProps): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(['dashboard']);
  const navigate = useNavigate();

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    try {
      navigate(getTenantAwarePath(RoutePath[`${e.key.toUpperCase()}`], userDetails.data?.organizationCode?.toLowerCase()))
      setSelectedKeys(e.keyPath)
    } catch (error) {
      navigate(getTenantAwarePath(RoutePath.DASHBOARD, userDetails.data?.organizationCode?.toLowerCase()))
      setSelectedKeys(['dashboard'])
    }
  };

  const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], roles?: string[]): MenuItem => {

    const hasAccess = roles ? roles.some(role => userDetails.data?.grantedAuthorities.includes(role)) : true;

    if (children || (roles && hasAccess)) {
      return {
        key,
        icon,
        children,
        label,
      } as MenuItem;
    }
  }

  const items: MenuItem[] = [
    getItem("Home", "dashboard", <HomeOutlined />),

    getItem("Master Data", "master", <DatabaseOutlined />, [
      getItem("Service", "products", <SolutionOutlined />, undefined, [
        "ROLE_PRODUCT_RO",
        "ROLE_PRODUCT_RW",
      ]),
      getItem("Consumable", "consumables", <ProductOutlined />, undefined, [
        "ROLE_CONSUMABLE_RO",
        "ROLE_CONSUMABLE_RW",
      ]),
      getItem("Customer", "customers", <ContactsOutlined />, undefined, [
        "ROLE_CUSTOMER_RO",
        "ROLE_CUSTOMER_RW",
      ]),
      getItem("Employee", "employees", <UserOutlined />, undefined, [
        "ROLE_EMPLOYEE_RO",
        "ROLE_EMPLOYEE_RW",
      ]),
    ]),

    getItem("Sales", "sales", <FundProjectionScreenOutlined />, [
      getItem("Quotations", "quotes", <ContainerOutlined />, undefined, [
        "ROLE_QUOTE_RO",
        "ROLE_QUOTE_RW",
      ]),
      getItem("Workorders", "workorders", <FileSyncOutlined />, undefined, [
        "ROLE_WORKORDER_RO",
        "ROLE_WORKORDER_RW",
      ]),
      getItem("Timesheets", "timesheets", <CalendarOutlined />, undefined, [
        "ROLE_TIMESHEET_RO",
        "ROLE_TIMESHEET_RW",
      ]),
      getItem("Invoices", "invoices", <AuditOutlined />, undefined, [
        "ROLE_INVOICE_RO",
        "ROLE_INVOICE_RW",
      ]),
    ]),

    getItem("Users & Roles", "userAndRole", <UsergroupAddOutlined />, [
      getItem("Users", "users", <UserOutlined />, undefined, [
        "ROLE_USER_RO",
        "ROLE_USER_RW",
      ]),
      getItem("Roles", "roles", <SafetyOutlined />, undefined, [
        "ROLE_ROLE_RO",
        "ROLE_ROLE_RW",
      ]),
    ]),
  ];


  return (
    <Sider
      breakpoint="lg"
      // onBreakpoint={(broken) => {
      //   console.log(broken);
      // }}
      onCollapse={(collapsed, type) => {
        setCollapsed(collapsed);
        // console.log(collapsed, type);
      }}
      collapsible
      collapsed={collapsed}
      theme="light"
    >
      {!collapsed && <FlexContainerSider>
        <FlexShrink>
          <ImageContainer>
            <StyledImage src={mispahLogo} alt="Asset Management System" />
          </ImageContainer>
        </FlexShrink>
      </FlexContainerSider>}
      <Menu
        onClick={handleMenuClick}
        style={{
          paddingTop: "65px",
        }}
        theme="light"
        defaultSelectedKeys={["dashboard"]}
        mode="inline"
        selectedKeys={selectedKeys}
        items={items}
      />
    </Sider>
  );
};

export default SiderComponent;
