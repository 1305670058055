import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../model/app-state.model";
import { IError } from "../../../model/error.model";
import Request from "../../../utils/Request";
import {
  ModuleListState,
  ModuleListPageable,
  ModuleUrl,
} from "../constants";
import { showToast } from "../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../common/Toast/constants";
import { IParamsPageRequest } from "../../../model/page.model";

const initialState: ModuleListState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const moduleList = createAsyncThunk<
ModuleListPageable,
  IParamsPageRequest | null,
  { state: AppState; rejectValue: IError }
>("moduleList", async (params, { dispatch, rejectWithValue }) => {
  return new Promise<ModuleListPageable>((resolve, reject) => {
    try {
      const successFn = (
        data: ModuleListPageable,
        headers,
        isSuccess,
        status
      ) => resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      api.get(ModuleUrl.LIST, params);
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const moduleListSlice = createSlice({
  name: "moduleList",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(moduleList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        moduleList.fulfilled,
        (state, action: PayloadAction<ModuleListPageable>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(moduleList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = moduleListSlice.actions;
export default moduleListSlice.reducer;
