import React, { Suspense } from 'react';
import ShimmerLoader from '../common/ShimmerLoader';

const withLazyComponent = (
  LazyComponent: React.ComponentType<any>,
  LoaderComponent = ShimmerLoader
): React.ComponentType => {
  const SuspenseWrapper = (props: any) => (
    <Suspense fallback={<LoaderComponent />}>
      <LazyComponent {...props} />
    </Suspense>
  );
  return SuspenseWrapper;
};

export default withLazyComponent;
