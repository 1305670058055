import React, { useEffect } from 'react';
import { IStateType } from '../../../model/app-state.model';
import { IToast } from '../constants';
import { ToastContainer } from './styles';
import { notification } from 'antd';

export type IToastProps = {
  showToast: (toastObj: IToast) => void;
  removeToast: () => void;
  toast: IStateType;
};
const ToastComponent = (props: IToastProps): JSX.Element | null => {
  const { type, position, timeout } = props.toast.data || {};

  const openNotificationWithIcon = (toast: IToast) => {
    notification.open({
      message: toast.message,
      description: toast.details,
      type: toast.type,
      duration: (toast?.timeout / 1000) || 4.5
    });
  };
  
  
  useEffect(() => {
    if (type) {
      setTimeout(() => {
        props.removeToast();
      }, timeout);
      openNotificationWithIcon(props.toast.data)
    }
  }, [props.toast]);

  if (!type) {
    return null;
  }

  return (
    <ToastContainer className={position} data-testid="toast-testid">
      {/* <div
        className={
          type.toLowerCase() +
          ' toast flex flex-align-center flex-justify-space-between'
        }
      >
        <div className="flex flex-align-center">
          <div className="details">
            <span>{type.toUpperCase()}</span>
            <p>{message.split('.')[0]}</p>
            <p>{message.split('.')[1]}</p>
          </div>
        </div>
        <div
          className="close-icon"
          onClick={props.removeToast}
          data-testid="toast-close-button-testid"
        >
          <span>X</span>
        </div>
      </div> */}
    </ToastContainer>
  );
};

export default ToastComponent;
