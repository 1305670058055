import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../model/app-state.model";
import { IError } from "../../../../model/error.model";
import Request from "../../../../utils/Request";
import {
  EmployeeUrl,
  EmployeeDepartmentListPageable,
  EmployeeDepartmentListState,
} from "../constants";
import { showToast } from "../../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../../common/Toast/constants";
import { IParamsPageRequest } from "../../../../model/page.model";

const initialState: EmployeeDepartmentListState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const employeeDepartmentList = createAsyncThunk<
  EmployeeDepartmentListPageable,
  IParamsPageRequest,
  { state: AppState; rejectValue: IError }
>("employeeDepartmentList", async (params, { dispatch, rejectWithValue }) => {
  return new Promise<EmployeeDepartmentListPageable>((resolve, reject) => {
    try {
      const successFn = (data: EmployeeDepartmentListPageable, headers, isSuccess, status) =>
        resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      const url = EmployeeUrl.DEPARTMENTS;
      api.get(url, params);
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const employeeDepartmentListSlice = createSlice({
  name: "employeeDepartmentList",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(employeeDepartmentList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        employeeDepartmentList.fulfilled,
        (state, action: PayloadAction<EmployeeDepartmentListPageable>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(employeeDepartmentList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = employeeDepartmentListSlice.actions;
export default employeeDepartmentListSlice.reducer;
