import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../../../model/app-state.model'
import { IError } from '../../../model/error.model'
import Request from '../../../utils/Request'
import { currentUserDetailsState, IUserDetailsResponse, UserUrls } from '../constants'
import { showToast } from '../../Toast/slice/toastSlice'
import { IToastType } from '../../Toast/constants'

const initialState: currentUserDetailsState = {
  isLoading: false,
  data: null,
  error: null,
}

// Define the async thunk
export const currentUser = createAsyncThunk<
  IUserDetailsResponse,
  any,
  { state: AppState; rejectValue: IError }
>('currentUser', async (_, { dispatch, rejectWithValue }) => {
  return new Promise<IUserDetailsResponse>((resolve, reject) => {
    try {
      const successFn = (data: IUserDetailsResponse, headers, isSuccess, status) =>
        resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      api.get(UserUrls.GET_CURRENT_USER);
    } catch (error) {
      return reject(error as IError);
    }
  });
})

// Create the slice
const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.data = null
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(currentUser.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(
        currentUser.fulfilled,
        (state, action: PayloadAction<IUserDetailsResponse>) => {
          state.isLoading = false
          state.data = action.payload
        },
      )
      .addCase(currentUser.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload as IError
      })
  },
})

export const { reset } = currentUserSlice.actions
export default currentUserSlice.reducer
