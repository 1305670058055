export interface ISignUpResponse {
    message: string;
  }
  
  export interface ISignUpRequest {
    name: string;
    email: string;
    password: string;
  }
  
  export enum SignUpUrl {
    SIGNUP = '/auth/signup',
  }