import { IError } from "../../../model/error.model";
import { IPageableResponse } from "../../../model/page.model";
import { Organization } from "../../UsersAndRoles/constants";

export interface IProductResponse {
  id: number;
  activity: string;
  subActivity: string;
  remarks: string;
  code: string;
  unit: IUnitOfMeasurementResponse;
  organization: Organization;
  quoteItemId?: number;
}

export interface IUnitOfMeasurementResponse {
  id: number;
  name: string;
  symbol: string;
  calculationMethod: string;
}

export interface ProductListState {
  isLoading: boolean;
  data: ProductListPageable | null;
  error: IError | null;
}

export interface ProductUnitListState {
  isLoading: boolean;
  data: ProductUnitListPageable | null;
  error: IError | null;
}

export interface ProductListPageable extends IPageableResponse {
  content: IProductResponse[];
}

export interface ProductUnitListPageable extends IPageableResponse {
  content: IUnitOfMeasurementResponse[];
}

export interface IProductRequest {
  id: string;
}

export interface ProductState {
  isLoading: boolean;
  data: IProductResponse | null;
  error: IError | null;
}

export enum ProductUrl {
  LIST = "/products",
  BY_ID = "/products/:productId",
  LIST_UNIT = "/products/units",
}
