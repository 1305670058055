import { IError } from "../../../model/error.model";
import { IPageableResponse } from "../../../model/page.model";
import { IRoleResponse, Organization } from "../../UsersAndRoles/constants";


export interface IEmployeeResponse {
    id: number;
    code: string;
    firstName: string;
    lastName: string;
    email: string;
    localPhone: string[]
    dob: string;
    nationality: string;
    joiningDate: string;
    country: string;
    city: string;
    addressLineOne: string;
    addressLineTwo: string;
    postalCode: number;
    department: IEmployeeDepartmentResponse;
    designation: IEmployeeDesignationResponse;
    education: string;
    homePhone: string[];
    updatedAt: string;
    organization: Organization;
    role: IRoleResponse;
    employeeContacts: IEmployeeContact[];
}

export interface IEmployeeContact {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    isActive: boolean;
}
export interface IEmployeeDepartmentResponse {
    id: string,
    name: string
    organization: string | Organization
}

export interface EmployeeDepartmentListPageable extends IPageableResponse{
    content: IEmployeeDepartmentResponse[]
}
export interface IEmployeeDesignationResponse {
    id: string,
    name: string
    organization: string | Organization
}

export interface EmployeeDesignationListPageable extends IPageableResponse{
    content: IEmployeeDesignationResponse[]
}

export interface EmployeeDepartmentListState {
    isLoading: boolean;
    data: EmployeeDepartmentListPageable | null;
    error: IError | null;
}

export interface EmployeeDesignationListState {
    isLoading: boolean;
    data: EmployeeDesignationListPageable | null;
    error: IError | null;
}

export interface EmployeeListState {
    isLoading: boolean;
    data: EmployeeListPageable | null;
    error: IError | null;
}

export interface EmployeeListPageable extends IPageableResponse{
    content: IEmployeeResponse[]
}

export interface EmployeeState {
    isLoading: boolean;
    data: IEmployeeResponse | null;
    error: IError | null;
}
export interface IEmployeeRequest {
    id: string
}

export enum EmployeeUrl {
    LIST = '/employees',
    BY_ID = '/employees/:employeeId',
    DEPARTMENTS = '/employees/departments',
    DESIGNATIONS = '/employees/designations',
}