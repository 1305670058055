import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStateType } from "../../../model/app-state.model";
import { IToast, IToastPosition } from "../constants";

const initialState: IStateType = {
  data: null,
  isLoading: false,
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
    showToast: (state, action: PayloadAction<IToast>) => {
      state.data = {
        message: "Something went wrong",
        details: "",
        position: IToastPosition.TOP_RIGHT,
        timeout: 2000,
        ...action.payload,
      };
    },
  },
});

export const { reset, showToast } = toastSlice.actions;
export default toastSlice.reducer;
