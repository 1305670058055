import { IError } from "../../../model/error.model";
import { IPageableResponse } from "../../../model/page.model";
import { Organization } from "../../UsersAndRoles/constants";

export interface IConsumableResponse {
  id: string;
  name: string;
  type: string;
  code: string;
  remarks: string;
  unit: IUnitOfMeasurementResponse;
  organization: Organization;
  quoteItemConsumableId?: number;
}

export interface IUnitOfMeasurementResponse {
  id: number;
  name: string;
  symbol: string;
  calculationMethod: string;
}

export interface ConsumableListState {
  isLoading: boolean;
  data: ConsumableListPageable | null;
  error: IError | null;
}

export interface ConsumableUnitListState {
  isLoading: boolean;
  data: ConsumableUnitListPageable | null;
  error: IError | null;
}

export interface ConsumableListPageable extends IPageableResponse {
  content: IConsumableResponse[];
}

export interface ConsumableUnitListPageable extends IPageableResponse {
  content: IUnitOfMeasurementResponse[];
}

export interface IConsumableRequest {
  id: string;
}

export interface ConsumableState {
  isLoading: boolean;
  data: IConsumableResponse | null;
  error: IError | null;
}

export enum ConsumableUrl {
  LIST = "/consumables",
  BY_ID = "/consumables/:consumableId",
  LIST_UNIT = "/consumables/units",
}
