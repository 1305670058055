import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../../model/app-state.model';
import { IError } from '../../../model/error.model';
import Request from '../../../utils/Request';
import { UserState, IUserResponse, IUserRequest, UserUrl } from '../constants';
import { showToast } from '../../../common/Toast/slice/toastSlice';
import { IToastType } from '../../../common/Toast/constants';

const initialState: UserState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const userUpdate = createAsyncThunk<
IUserResponse,
IUserRequest,
  { state: AppState, rejectValue: IError }
>('userUpdate', async (request, { dispatch, rejectWithValue }) => {
    return new Promise<IUserResponse>((resolve, reject) => {
        try {
          const successFn = (data: IUserResponse, headers, isSuccess, status) =>
            resolve(data);
          const errorFn = (error: IError, headers, status) => {
            dispatch(
              showToast({
                message: error?.message,
                type: IToastType.ERROR,
              })
            );
            return reject(rejectWithValue(error));
          };
    
          const api = new Request(dispatch, successFn, errorFn, false);
          if(request.id){
            const url = UserUrl.BY_ID.replace(":userId", request.id);
            api.put(url, request);
          }else{
            api.post(UserUrl.LIST, request);
          }
        } catch (error) {
          return reject(error as IError);
        }
      });
});

// Create the slice
const userUpdateSlice = createSlice({
  name: 'userUpdate',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userUpdate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(userUpdate.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(userUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = userUpdateSlice.actions;
export default userUpdateSlice.reducer;