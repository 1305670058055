import React from "react";
import GlobalStyle from "./styles";
import ReduxProvider from "./redux";
import { ConfigProvider } from "antd";
import AppRoutes from "./routes/index";
import AuthProviderContainer from "./common/AuthProvider";
import ToastContainer from "./common/Toast";

const App: React.FC = () => {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#00b96b" } }}>
      <ReduxProvider>
        <AuthProviderContainer>
          <AppRoutes />
        </AuthProviderContainer>
        <GlobalStyle />
        <ToastContainer />
      </ReduxProvider>
    </ConfigProvider>
  );
};

export default App;
