import axios, { AxiosInstance } from "axios";
import { UnknownAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as qs from "qs";
import { AppState } from "../model/app-state.model";
import { IError } from "../model/error.model";
import { ObjectLiteral } from "../types";
import { getObjectValue, httpStatusCodes, isAuthenticated } from "./common";
import { LogoutUrl } from "../pages/Logout/constants";
import axiosRetry from "axios-retry";
import { logout } from "../pages/Logout/slice/logoutSlice";
import { setUnauthorized } from "../common/AuthProvider/slice/authSlice";

// Configure axios retry logic
axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: (retryCount) => {
    console.log(`Retry attempt: ${retryCount}`);
    return retryCount * 1000; // Time between retries in ms
  },
  retryCondition: (error) => {
    // Retry on network errors or 5xx server responses
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response?.status === 503;
  },
});

type ErrorFunction = (
  error: IError | null,
  headers: ObjectLiteral | null,
  status: number
) => any;

type SuccessFunction = (
  data: any,
  headers: ObjectLiteral,
  isSuccess: boolean,
  status?: number
) => void;

class Request {

  constructor(
    private readonly dispatch: ThunkDispatch<AppState, unknown, UnknownAction>,
    private readonly successFn: SuccessFunction,
    private readonly errorFn: ErrorFunction,
    private readonly authorize = true,
    private readonly tenantCode?: string | undefined
  ) { }

  static baseUrl(tenantCode = ""): string | undefined {
    const rootUrl = process.env.REACT_APP_BASE_URL || '/api';
    if (!tenantCode) {
      return rootUrl;
    }
    return rootUrl + `/${tenantCode}`;
  }

  /**
   * GET axios instance and do things that are common for every request
   */
  instance(config?: any): AxiosInstance {
    const headers = {
      "Content-Type": config ? config : "application/json",
    };
    if (config?.isMultipart) {
      headers["Content-Type"] = "multipart/form-data";
    }

    const instance = axios.create({
      baseURL: Request.baseUrl(this.tenantCode),
      withCredentials: true,
      timeout: 300000,
      headers,
    });
    // Response Interceptor
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = getObjectValue(error.response, "status", null);
        if (
          status === httpStatusCodes.FORBIDDEN &&
          error.response.config.url !== LogoutUrl.LOGOUT
        ) {
          this.dispatch(logout());
        }
        if (status === httpStatusCodes.UNAUTHORIZED &&
          error.response.config.url !== LogoutUrl.LOGOUT) {
          this.dispatch(setUnauthorized(error?.response?.data));
        }
        if (error.code === "ERR_NETWORK") {
          return Promise.reject({
            ...error,
            response: {
              data: {
                error: error.code,
                message: 'Service Down Try Again',
                statusCode: 500,
              } as IError,
            },
          });
        }
        return Promise.reject(error);
      }
    );
    return instance;
  }

  /**
   * Make GET Requests
   * @param {string} url
   * @param {object} params
   */
  get(url: string, params: ObjectLiteral = {}, config?: string): any {
    if (this.authorize && !isAuthenticated()) {
      return this.errorFn(null, null, httpStatusCodes.BAD_REQUEST);
    }
    return this.instance(config)
      .get(url, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
      .then((response) => {
        const data = getObjectValue(response, "data", null);
        const headers = getObjectValue(response, "headers", null);
        const isSuccess = true;
        this.successFn(data, headers, isSuccess);
        return response;
      })
      .catch((error) => {
        const data = getObjectValue(error.response, "data", null);
        const headers = getObjectValue(error.response, "headers", null);
        const status = getObjectValue(error.response, "status", null);
        this.errorFn(data, headers, status);
      });
  }

  /**
   * Make POST Requests
   * @param {string} url
   * @param {object} params
   */
  post(url: string, params: ObjectLiteral = {}, config?: any): any {
    if (this.authorize && !isAuthenticated()) {
      return this.errorFn(null, null, httpStatusCodes.BAD_REQUEST);
    }
    if (config?.isMultipart) {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]: [string, any]) => {
        formData.append(key, value);
      });
      params = formData;
    }
    return this.instance(config)
      .post(url, params)
      .then((response) => {
        const data = getObjectValue(response, "data", null);
        const headers = getObjectValue(response, "headers", null);
        const status = getObjectValue(response, "status", null);
        const isSuccess = true;
        this.successFn(data, headers, isSuccess, status);
        return response;
      })
      .catch((error) => {
        const data = getObjectValue(error.response, "data", null);
        const headers = getObjectValue(error.response, "headers", null);
        const status = getObjectValue(error.response, "status", null);
        this.errorFn(data, headers, status);
      });
  }
  /**
   * Make PUT Requests
   * @param {string} url
   * @param {object} params
   */
  put(url: string, params: ObjectLiteral = {}, config?: string): any {
    if (this.authorize && !isAuthenticated()) {
      return this.errorFn(null, null, httpStatusCodes.BAD_REQUEST);
    }
    return this.instance(config)
      .put(url, params)
      .then((response) => {
        const data = getObjectValue(response, "data", null);
        const headers = getObjectValue(response, "headers", null);
        const status = getObjectValue(response, "status", null);
        const isSuccess = true;
        this.successFn(data, headers, isSuccess, status);
        return response;
      })
      .catch((error) => {
        const data = getObjectValue(error.response, "data", null);
        const headers = getObjectValue(error.response, "headers", null);
        const status = getObjectValue(error.response, "status", null);
        this.errorFn(data, headers, status);
      });
  }

  /**
   * Make PATCH Requests
   * @param {string} url
   * @param {object} params
   */
  patch(url: string, params: ObjectLiteral = {}, config?: any): any {
    if (this.authorize && !isAuthenticated()) {
      return this.errorFn(null, null, httpStatusCodes.BAD_REQUEST);
    }
    if (config?.isMultipart) {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]: [string, any]) => {
        formData.append(key, value);
      });
      params = formData;
    }
    return this.instance(config)
      .patch(url, params)
      .then((response) => {
        const data = getObjectValue(response, "data", null);
        const headers = getObjectValue(response, "headers", null);
        const isSuccess = true;
        this.successFn(data, headers, isSuccess);
      })
      .catch((error) => {
        const data = getObjectValue(error.response, "data", null);
        const headers = getObjectValue(error.response, "headers", null);
        const status = getObjectValue(error.response, "status", null);
        this.errorFn(data, headers, status);
      });
  }

  /**
   * Make DELETE Requests
   * @param {string} url
   * @param {object} params
   */
  delete(url: string, params: ObjectLiteral = {}, config?: string): any {
    if (this.authorize && !isAuthenticated()) {
      return this.errorFn(null, null, httpStatusCodes.BAD_REQUEST);
    }
    return this.instance(config)
      .delete(url, params)
      .then((response) => {
        const data = getObjectValue(response, "data", null);
        const headers = getObjectValue(response, "headers", null);
        const status = getObjectValue(response, "status", null);
        const isSuccess = true;
        this.successFn(data, headers, isSuccess, status);
      })
      .catch((error) => {
        const data = getObjectValue(error.response, "data", null);
        const headers = getObjectValue(error.response, "headers", null);
        const status = getObjectValue(error.response, "status", null);
        this.errorFn(data, headers, status);
      });
  }
}

export default Request;
