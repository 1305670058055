import { connect } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../../model/app-state.model'
import AuthProvider from './components/AuthProvider'
import {
  currentUser as getCurrentUser,
  reset as resetGetCurrentUser,
} from './slice/getCurrentUserSlice'

const mapStateToProps = (state: AppState) => {
  const { currentUser} = state
  return {
    currentUser,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
    resetGetCurrentUser: bindActionCreators(resetGetCurrentUser, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider)
