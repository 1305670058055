import styled from "styled-components";

export const FlexContainerHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 40px;
  float: left;
`;

export const FlexContainerSider = styled.div`
  display: flex;
  align-items: center;
  padding: 40px;
  float: left;
`;

export const FlexShrink = styled.div`
  flex-shrink: 0;
`;

export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem; /* Tailwind gap-2 is 0.5rem */
  margin-top: 1.25rem; /* Tailwind mt-5 is 1.25rem */

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-around;
    margin-top: 0; /* Remove the top margin on md screens */
  }
`;

export const StyledImage = styled.img`
  height: 2rem; /* Tailwind h-8 is 2rem */

  &:nth-child(2) {
    @media (min-width: 768px) {
      margin-left: 2.5rem; /* Tailwind md:ml-10 is 2.5rem */
    }
  }
`;

export const HeaderUserWrapper = styled.div`
  position: relative;
  display: inline-flex;
  float: right;
  border-radius: 5px;
  margin-right: 50px;
  button {
    background: inherit;
    border: 1px solid #ebebeb;
    color: #575757;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    color: var(--dark-grey-color);
    max-height: 40px;
    min-width: 80px;
    & > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  & .name-container {
    display: flex;
    & .name {
      display: flex;
      align-items: center;
    }
    & .divider {
      border: 1px solid var(--light-grey-border-color);
      margin: 0px 8px;
    }
    .header-icon{
      display: flex;
      margin-left: 10px;
      align-items: center;
    }
    .user-short-name-circle {
      font-size: 10px;
      line-height: 12px;
      background: var(--dark-primary-color);
      mix-blend-mode: normal;
      border: 1px solid var(--dark-primary-color);
      color: var(--white-color);
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: -5px;
    }
  }
`;