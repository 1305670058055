import React from "react";
import { Breadcrumb, Layout, theme } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getBreadcrumbItems } from "../../../utils/common";

const { Content } = Layout;

const ContentComponent = (): JSX.Element => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const location = useLocation();


  const itemRender = (currentRoute, params, items, paths) => {
    const isLast = currentRoute?.path === items[items.length - 1]?.path;

    return isLast ? (
      <span>{currentRoute.title}</span>
    ) : (
      <Link to={`/${paths.join("/")}`}>{currentRoute.title}</Link>
    );
  };
  return (
    <>
      <Content style={{ margin: "0 16px" }}>
        <Breadcrumb
          style={{ margin: "16px 0" }}
          itemRender={itemRender}
          items={getBreadcrumbItems(location.pathname)}
        />
        <div
          style={{
            padding: 24,
            minHeight: 360,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </div>
      </Content>
    </>
  );
};

export default ContentComponent;
