import { ObjectLiteral } from "../types";
import { RoutePath } from "../routes/constants";

export const httpStatusCodes = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  SERVER_FAILED: 500,
  FORBIDDEN: 403,
};

export const extractValue = (obj, path) =>
  path
    .split(".")
    .reduce(
      (o, key) => (o && o[key] !== "undefined" ? o[key] : undefined),
      obj
    );

// Transform a single object based on config
export const transformObject = (obj, transformConfig) => {
  return Object.entries(transformConfig).reduce(
    (acc, [key, valuePath]) => {
      acc[key] = extractValue(obj[key], valuePath);
      return acc;
    },
    { ...obj }
  );
};

export function getObjectValue(
  obj: ObjectLiteral,
  key: string,
  defaultValue = null
): any {
  const enumerator: ObjectLiteral = obj;
  const property = key;

  if (isEnumerable(enumerator) && keyExists(property, enumerator)) {
    return enumerator[property];
  }
}

export function keyExists(key: string, obj: ObjectLiteral): boolean {
  if (
    (Array.isArray(obj) && key in obj) ||
    (obj instanceof Object && Object.prototype.hasOwnProperty.call(obj, key))
  ) {
    return true;
  }

  return false;
}

export function getUserShortName(firstName = "", lastName = ""): string | null {
  if (!firstName || !lastName) return "";
  return (firstName.charAt(0) + "" + lastName.charAt(0)).toUpperCase();
}

export function isEnumerable(obj: ObjectLiteral): boolean {
  let isEnumerable = false;

  if (Array.isArray(obj) || obj instanceof Object) {
    isEnumerable = true;
  }

  return isEnumerable;
}

export function isAuthenticated(): boolean {
  return true;
}

export function getCurrentTenantFromUrl(uri?: string): string | undefined {
  uri = uri || window.location.pathname;
  const currentTenant = uri.length > 1 && uri.split("/")[1];
  if (!currentTenant) {
    console.error("Invalid tenant in the URI: ", uri);
    return undefined;
  }
  return currentTenant;
}

export function getTenantAwarePath(path: RoutePath, tenantId: string): string {
  return path.replace(":tenant", tenantId);
}

export function getBreadcrumbItems(pathname: string) {
  const paths = pathname.split("/");
  const breadcrumbItems = [];

  for (let i = 2; i < paths.length; i++) {
    const path = paths[i];
    if (path) {
      let title = path.charAt(0).toUpperCase() + path.slice(1);
      if (path === "products") {
        title = "Services";
      } else if (path.includes('-')) {
        title = path.charAt(0) + path.slice(1);
      } else {
        title = path.charAt(0).toUpperCase() + path.slice(1);
      }

      const url =
        i === paths.length - 1
          ? undefined
          : `${paths.slice(0, i + 1).join("/")}`;

      breadcrumbItems.push({
        title,
        url,
        path: url,
      });
    }
  }

  return breadcrumbItems;
}

export const formatNumber = (number: number): string => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};


export const getStatusColor = (status) => {
  switch (status) {
    case "SENT":
    case "REVIEW":
    case "IN_PROGRESS":
    case "SUBMITTED":
      return "geekblue";
    case "PAID":
    case "PARTIAL_PAID":
    case "APPROVED":
    case "BILLED":
    case "COMPLETED":
    case "SERVICE_BILLED":
    case "CONSUMABLE_BILLED":
      return "green";
    case "DRAFT":
    case "PENDING":
      return undefined;
    default:
      return "volcano";
  }
};

export const toReadableStatusFormat = (input: string): string => {
  // Split the string by underscores and map each word
  return input
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}