import React, { useEffect } from "react";
import { Dropdown, Layout, theme } from "antd";
import oneFinanceLogo from "../../../assets/images/one-finance-logo.png";
import {
  FlexContainerHeader,
  FlexShrink,
  HeaderUserWrapper,
  ImageContainer,
  StyledImage,
} from "../styles";
import { IStateType } from "../../../model/app-state.model";
import { getUserShortName } from "../../../utils/common";
import { CaretDownOutlined, LogoutOutlined } from "@ant-design/icons";
import type { MenuProps } from 'antd';
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/constants";
import { LogoutState } from "../../../pages/Logout/constants";
import { AuthState } from "../../AuthProvider/slice/authSlice";

const { Header } = Layout;

export interface IHeaderProps {
  userDetails: IStateType;
  logoutState: LogoutState;
  authState: AuthState;
  logout: () => void
}

const HeaderComponent = ({ userDetails, logoutState, authState, logout }: IHeaderProps): JSX.Element => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const shortUsername = (
    <span className="user-short-name-circle mr-10">
      {getUserShortName(userDetails.data?.name, userDetails.data?.name)}
    </span>
  );

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (
      !logoutState?.isLoading &&
      !logoutState?.error && 
      logoutState?.data
    ) {
      localStorage.clear();
      document.cookie = 'authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      navigate(RoutePath.LOGIN);
    }
  }, [logoutState]);

  useEffect(() => {
    if (authState?.isUnauthorized) {
      // Reset the unauthorized state if needed
      // dispatch(resetUnauthorized()); // Uncomment if you want to reset after navigating
      navigate('/login'); // Navigate to the login page on unauthorized access
    }
  }, [authState, navigate]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Logout',
      icon: <LogoutOutlined />,
      onClick: handleLogout
    }
  ]

  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
        display: 'inline-block',
        alignContent: "center"
      }}
    >
      <FlexContainerHeader>
        <FlexShrink>
          <ImageContainer>
            <StyledImage src={oneFinanceLogo} alt="Asset Management System" />
          </ImageContainer>
        </FlexShrink>
      </FlexContainerHeader>
      <HeaderUserWrapper>
        <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
          <button
            type="button"
            className="btn flex flex-align-center"
          >
            <div className="name-container">
              <span className="name">{userDetails?.data?.organizationCode || "NA"}</span>
              <span className="divider"></span>
              {shortUsername}
              <span className="header-icon" ><CaretDownOutlined /></span>
            </div>

          </button>
        </Dropdown>
      </HeaderUserWrapper>
    </Header>
  );
};

export default HeaderComponent;
