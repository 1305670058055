import { IAuditable } from "../../../model/auditable.model";
import { IError } from "../../../model/error.model";
import { IPageableResponse } from "../../../model/page.model";
import { IGetRequest } from "../../../model/request.model";
import { IConsumableResponse } from "../../MasterData/Consumable/constants";
import { ICustomerResponse } from "../../MasterData/Customer/constants";
import { IEmployeeResponse } from "../../MasterData/Employee/constants";
import { IProductResponse } from "../../MasterData/Product/constants";
import { Organization } from "../../UsersAndRoles/constants";
import { IQuoteItem, IQuoteItemConsumable } from "../Quote/constants";

export interface IWorkOrderResponse extends IAuditable {
  id: string;
  customer: ICustomerResponse;
  organization: Organization;
  employee: IEmployeeResponse;
  version: number;
  notes: string;
  title: string;
  status: WorkOrderStatus;
  workOrderItems: IWorkOrderItem[];
  workOrderItemConsumables: IWorkOrderItemConsumable[];
}

export interface IWorkOrderItem extends IAuditable {
  id: number;
  product: IProductResponse;
  quantity: number;
  pricePerUnit: number;
  quoteItem: IQuoteItem;
  version: number;
  isActive: boolean;
  workOrderId: string;
  workOrderTitle: string;
  remarks?: string;
}

export interface IWorkOrderItemConsumable extends IAuditable {
  id: number;
  consumable: IConsumableResponse;
  quantity: number;
  pricePerUnit: number;
  quoteItemConsumable: IQuoteItemConsumable;
  version: number;
  isActive: boolean;
  remarks?: string;
}

export enum WorkOrderStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  BILLED = "BILLED",
}
export interface WorkOrderListState {
  isLoading: boolean;
  data: WorkOrderListPageable | null;
  error: IError | null;
}

export interface WorkOrderListPageable extends IPageableResponse {
  content: IWorkOrderResponse[];
}

export interface IWorkOrderRequest extends IGetRequest {
  id: string;
}

export interface WorkOrderState {
  isLoading: boolean;
  data: IWorkOrderResponse | null;
  error: IError | null;
}

export enum WorkOrderUrl {
  LIST = "/workOrders",
  BY_ID = "/workOrders/:workOrderId",
}
