import React, { ReactElement, useState } from 'react'
import { AsyncThunk } from '@reduxjs/toolkit'
import { IStateType } from '../../../model/app-state.model'
import { IUserDetailsResponse } from '../constants'

type VoidFunction = () => void

type IContext = {
  userDetails: IStateType
  tenantCode: string
  validateUser: AsyncThunk<IUserDetailsResponse, any, any>
  resetGetCurrentUser: VoidFunction | null
  setTenantCode: (tenantId: string) => void
}

type IProps = {
  getCurrentUser: AsyncThunk<IUserDetailsResponse, any, any>
  resetGetCurrentUser: VoidFunction
  currentUser: IStateType
  children: ReactElement
}

export const AuthContext = React.createContext<IContext>({
  userDetails: { isLoading: false, data: null, error: null },
  tenantCode: '',
  setTenantCode: (id) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    null
  },
  validateUser: null,
  resetGetCurrentUser: null,
})

const AuthProvider = (props: IProps): ReactElement => {
  const [tenantCode, setTenantCode] = useState<string>('')

  return (
    <AuthContext.Provider
      value={{
        userDetails: props.currentUser,
        tenantCode,
        setTenantCode,
        validateUser: props.getCurrentUser,
        resetGetCurrentUser: props.resetGetCurrentUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
