import styled from 'styled-components';

export const ToastContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  margin: 15px;
  animation: show_toast 0.5s ease forwards;

  @keyframes show_toast {
    0% {
      transform: translateX(100%);
    }
    40% {
      transform: translateX(20%);
    }
    60% {
      transform: translateX(-10%);
    }
    80% {
      transform: translateX(0);
    }
  }
  .toast {
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.15);
    min-width: 300px;
    max-width: 400px;
    .details {
      & > span {
        color: var(--white-color);
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
      }
      & > p {
        color: #878787;
        font-size: 12px;
        line-height: 20px;
        color: var(--white-color);
      }
    }
    & > .close-icon {
      color: var(--white-color);
    }
    &.info {
      background-color: var(--white-color);
      & > .close-icon {
        color: var(--dark-grey-color);
      }
      .details {
        & > span {
          color: var(--dark-grey-color);
        }
        & > p {
          color: var(--light-grey-color);
        }
      }
    }
    &.success {
      color: var(--color-white);
      background-color: var(--dark-primary-color);
      /* background-color: #60c3ad; */
    }
    &.error {
      color: var(--color-white);
      background-color: var(--dark-red-color);
    }

    & > .close-icon {
      cursor: pointer;
      text-align: center;
      line-height: 40px;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      &:hover {
        font-weight: 800;
      }
    }
  }
  &.top-left {
    top: 0;
    left: 0;
  }
  &.top-right {
    top: 0;
    right: 0;
  }
  &.bottom-left {
    bottom: 0;
    left: 0;
  }
  &.bottom-right {
    bottom: 0;
    right: 0;
  }
`;
