import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../model/app-state.model";
import { IError } from "../../../../model/error.model";
import Request from "../../../../utils/Request";
import {
  CustomerListPageable,
  CustomerUrl,
  CustomerListState
} from "../constants";
import { showToast } from "../../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../../common/Toast/constants";
import { IParamsPageRequest } from "../../../../model/page.model";

const initialState: CustomerListState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const customerList = createAsyncThunk<
  CustomerListPageable,
  IParamsPageRequest | null,
  { state: AppState; rejectValue: IError }
>("customerList", async (params, { dispatch, rejectWithValue }) => {
  return new Promise<CustomerListPageable>((resolve, reject) => {
    try {
      const successFn = (
        data: CustomerListPageable,
        headers,
        isSuccess,
        status
      ) => resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      api.get(CustomerUrl.LIST, params);
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const customerListSlice = createSlice({
  name: "customerList",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        customerList.fulfilled,
        (state, action: PayloadAction<CustomerListPageable>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(customerList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = customerListSlice.actions;
export default customerListSlice.reducer;
