import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../model/error.model';

export interface AuthState {
  isUnauthorized: boolean;
  error: IError | null;
}

const initialState: AuthState = {
  isUnauthorized: false,
  error: null,
};

// Create the slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUnauthorized: (state, action: PayloadAction<IError | null>) => {
      state.isUnauthorized = true;
      state.error = action.payload;
    },
    resetUnauthorized: (state) => {
      state.isUnauthorized = false;
      state.error = null;
    },
  },
});

export const { setUnauthorized, resetUnauthorized } = authSlice.actions;
export default authSlice.reducer;