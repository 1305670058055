import { DefaultOptionType } from "antd/es/select";
import { IError } from "../../model/error.model";
import { IPageableResponse } from "../../model/page.model";
import { IEmployeeResponse } from "../MasterData/Employee/constants";

export interface UserListState {
  isLoading: boolean;
  data: UserListPageable | null;
  error: IError | null;
}

export interface ModuleListState {
    isLoading: boolean;
    data: ModuleListPageable | null;
    error: IError | null;
  }

export interface RoleListState {
    isLoading: boolean;
    data: RoleListPageable | null;
    error: IError | null;
  }

export interface UserState {
    isLoading: boolean;
    data: IUserResponse | null;
    error: IError | null;
}

export interface RoleState {
    isLoading: boolean;
    data: IRoleResponse | null;
    error: IError | null;
}

export interface IUserResponse {
  id: number;
  username: string;
  name: string;
  employee: IEmployeeResponse;
}

export interface IRoleResponse {
    id: number;
    name: string;
    description: string;
    isActive: boolean;
    updatedAt: string;
    Organization: Organization;
    roleStatements: IRoleStatementResponse[];
    roleStatementsSet?: any
  }


  export interface IModuleResponse {
    id: number;
    name: string;
    displayName: string;
    description: string;
    isActive: boolean;
    updatedAt: string;
    organization: Organization;
    subModules: ISubModuleResponse[];
  }

  export interface ISubModuleResponse {
        id: number;
        name: string;
        displayName: string;
        description: string;
        isActive: boolean;
        module: number;
    }

export interface UserListPageable extends IPageableResponse {
  content: IUserResponse[];
}

export interface RoleListPageable extends IPageableResponse {
    content: IRoleResponse[];
  }

  export interface ModuleListPageable extends IPageableResponse {
    content: IModuleResponse[];
  }

export interface IUserRequest {
    id: string
    roleId?: number
}

export interface IRoleRequest {
    id: string
}

export interface Organization {
    id: number;
    name: string;
    description: string;
    updatedAt: string;
  }

  export interface IRoleStatementResponse{
    isActive: boolean;
    id?: number,
    name?: string,
    subModule?: number,
    permission?: RolePermission,
    subModuleId?: number,
    subModuleName?: string,
    moduleId?: number
}

export enum RolePermission{
    RW = 'RW',
    RO = 'RO'
}


export enum UserUrl {
    LIST = '/users',
    BY_ID = '/users/:userId',
}

export enum RoleUrl {
    LIST = '/roles',
    BY_ID = '/roles/:roleId',
}

export enum ModuleUrl {
    LIST = '/modules',
    BY_ID = '/modules/:moduleId',
}

export interface OptionType extends DefaultOptionType{
    label: string;
    value: string | number;
  }