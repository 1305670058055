import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../model/app-state.model';
import ToastComponent from './components/toast';
import { showToast, reset as removeToast } from './slice/toastSlice';

const mapStateToProps = (state: AppState) => {
  const { toast } = state;
  return {
    toast,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>
) => {
  return {
    showToast: bindActionCreators(showToast, dispatch),
    removeToast: bindActionCreators(removeToast, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastComponent);
