import styled from "styled-components";

export const ShimmerWrapper = styled.div`
  .shine {
    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    position: relative;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
`;

export const FlexWrapper = styled.div`
  &.flex-margin-right > div {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const LineWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  width: 100%;
`;

export const Box = styled.div`
  display: inline-block;
  height: 100px;
  width: 100px;
`;

export const Line = styled.span`
  height: 10px;
  width: 100%;
  max-width: 556px;
`;

export const Photo = styled.div`
  width: 325px;
  height: 100px;
`;
