import { IStateType } from '../../model/app-state.model'



export interface IUserDetailsResponse {
    name?: string
}

export interface currentUserDetailsState extends IStateType {
    data: IUserDetailsResponse | null
}

export enum UserUrls {
    GET_CURRENT_USER = 'users/currentUser',
}