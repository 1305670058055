import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../model/app-state.model";
import { IError } from "../../../../model/error.model";
import Request from "../../../../utils/Request";
import {
  ConsumableUnitListPageable,
  ConsumableUrl,
  ConsumableUnitListState
} from "../constants";
import { showToast } from "../../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../../common/Toast/constants";
import { IParamsPageRequest } from "../../../../model/page.model";

const initialState: ConsumableUnitListState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const consumableUnitList = createAsyncThunk<
  ConsumableUnitListPageable,
  IParamsPageRequest | null,
  { state: AppState; rejectValue: IError }
>("consumableUnitList", async (params, { dispatch, rejectWithValue }) => {
  return new Promise<ConsumableUnitListPageable>((resolve, reject) => {
    try {
      const successFn = (
        data: ConsumableUnitListPageable,
        headers,
        isSuccess,
        status
      ) => resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      api.get(ConsumableUrl.LIST_UNIT, params);
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const consumableUnitListSlice = createSlice({
  name: "consumableUnitList",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(consumableUnitList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        consumableUnitList.fulfilled,
        (state, action: PayloadAction<ConsumableUnitListPageable>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(consumableUnitList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = consumableUnitListSlice.actions;
export default consumableUnitListSlice.reducer;
