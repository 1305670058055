import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../pages/Login/slice/loginSlice";
import logoutReducer from "../pages/Logout/slice/logoutSlice";
import toastReducer from "../common/Toast/slice/toastSlice";
import getCurrentUserReducer from "../common/AuthProvider/slice/getCurrentUserSlice";
import getEmployeeListReducer from "../pages/MasterData/Employee/slice/getEmployeeListSlice";
import updateEmployeeReducer from "../pages/MasterData/Employee/slice/updateEmployeeSlice";
import getEmployeeReducer from "../pages/MasterData/Employee/slice/getEmployeeSlice";
import getUserListReducer from "../pages/UsersAndRoles/slice/getUserListSlice";
import getUserReducer from "../pages/UsersAndRoles/slice/getUserSlice";
import updateUserReducer from "../pages/UsersAndRoles/slice/updateUserSlice";
import getRoleListReducer from "../pages/UsersAndRoles/slice/getRoleListSlice";
import updateRoleReducer from "../pages/UsersAndRoles/slice/updateRoleSlice";
import getModuleListReducer from "../pages/UsersAndRoles/slice/getModuleListSlice";
import getRoleReducer from "../pages/UsersAndRoles/slice/getRoleSlice";
import getCustomerListReducer from "../pages/MasterData/Customer/slice/getCustomerListSlice";
import updateCustomerReducer from "../pages/MasterData/Customer/slice/updateCustomerSlice";
import getCustomerReducer from "../pages/MasterData/Customer/slice/getCustomerSlice";
import getProductListReducer from "../pages/MasterData/Product/slice/getProductListSlice";
import updateProductReducer from "../pages/MasterData/Product/slice/updateProductSlice";
import getProductReducer from "../pages/MasterData/Product/slice/getProductSlice";
import getProductUnitListReducer from "../pages/MasterData/Product/slice/getProductUnitListSlice";
import getQuoteListReducer from "../pages/Sales/Quote/slice/getQuoteListSlice";
import updateQuoteReducer from "../pages/Sales/Quote/slice/updateQuoteSlice";
import getQuoteReducer from "../pages/Sales/Quote/slice/getQuoteSlice";
import getWorkOrderListReducer from "../pages/Sales/WorkOrder/slice/getWorkOrderListSlice";
import updateWorkOrderReducer from "../pages/Sales/WorkOrder/slice/updateWorkOrderSlice";
import getWorkOrderReducer from "../pages/Sales/WorkOrder/slice/getWorkOrderSlice";
import getCustomerProductListReducer from '../pages/MasterData/Customer/slice/getCustomerProductListSlice';
import getCustomerConsumableListReducer from '../pages/MasterData/Customer/slice/getCustomerConsumableListSlice';
import getCustomerWorkOrderListReducer from '../pages/MasterData/Customer/slice/getCustomerWorkOrderListSlice';
import getTimeSheetListReducer from "../pages/Sales/TimeSheet/slice/getTimeSheetListSlice";
import updateTimeSheetReducer from "../pages/Sales/TimeSheet/slice/updateTimeSheetSlice";
import getTimeSheetReducer from "../pages/Sales/TimeSheet/slice/getTimeSheetSlice";
import getInvoiceListReducer from "../pages/Sales/Invoice/slice/getInvoiceListSlice";
import updateInvoiceReducer from "../pages/Sales/Invoice/slice/updateInvoiceSlice";
import getInvoiceReducer from "../pages/Sales/Invoice/slice/getInvoiceSlice";
import getConsumableListReducer from "../pages/MasterData/Consumable/slice/getConsumableListSlice";
import updateConsumableReducer from "../pages/MasterData/Consumable/slice/updateConsumableSlice";
import getConsumableReducer from "../pages/MasterData/Consumable/slice/getConsumableSlice";
import getConsumableUnitListReducer from "../pages/MasterData/Consumable/slice/getConsumableUnitListSlice";
import getCustomerLocationInsListReducer from "../pages/MasterData/Customer/slice/getCustomerLocationInsListSlice";
import updateCustomerLocationInsReducer  from "../pages/MasterData/Customer/slice/updateCustomerLocationInsSlice";
import getEmployeeDepartmentListReducer  from "../pages/MasterData/Employee/slice/getEmployeeDepartmentListSlice";
import getEmployeeDesignationListReducer from "../pages/MasterData/Employee/slice/getEmployeeDesignationListSlice";
import getInvoiceTaxListReducer from "../pages/Sales/Invoice/slice/getInvoiceTaxListSlice";
import getCustomerTimesheetProductListReducer from '../pages/MasterData/Customer/slice/getCustomerTimesheetProductListSlice';
import signUpReducer from "../pages/SignUp/slice/signUpSlice";
import getCustomerTimesheetConsumableListReducer from '../pages/MasterData/Customer/slice/getCustomerTimesheetConsumableListSlice';
import authReducer from '../common/AuthProvider/slice/authSlice';
import quoteSourceListReducer from "../pages/Sales/Quote/slice/getQuoteSourceListSlice";

const store = configureStore({
  reducer: {
    login: loginReducer,
    logout: logoutReducer,
    toast: toastReducer,
    currentUser: getCurrentUserReducer,
    employeeList: getEmployeeListReducer,
    employeeUpdate: updateEmployeeReducer,
    employeeGet: getEmployeeReducer,
    userGet: getUserReducer,
    userList: getUserListReducer,
    userUpdate: updateUserReducer,
    roleGet: getRoleReducer,
    roleList: getRoleListReducer,
    roleUpdate: updateRoleReducer,
    moduleList: getModuleListReducer,
    customerList: getCustomerListReducer,
    customerUpdate: updateCustomerReducer,
    customerGet: getCustomerReducer,
    productList: getProductListReducer,
    productUpdate: updateProductReducer,
    productGet: getProductReducer,
    productUnitList: getProductUnitListReducer,
    quoteList: getQuoteListReducer,
    quoteUpdate: updateQuoteReducer,
    quoteGet: getQuoteReducer,
    workOrderList: getWorkOrderListReducer,
    workOrderUpdate: updateWorkOrderReducer,
    workOrderGet: getWorkOrderReducer,
    customerProductList: getCustomerProductListReducer,
    customerWorkOrderList: getCustomerWorkOrderListReducer,
    timeSheetList: getTimeSheetListReducer,
    timeSheetUpdate: updateTimeSheetReducer,
    timeSheetGet: getTimeSheetReducer,
    invoiceList: getInvoiceListReducer,
    invoiceUpdate: updateInvoiceReducer,
    invoiceGet: getInvoiceReducer,
    consumableList: getConsumableListReducer,
    consumableUpdate: updateConsumableReducer,
    consumableGet: getConsumableReducer,
    consumableUnitList: getConsumableUnitListReducer,
    customerConsumableList: getCustomerConsumableListReducer,
    customerLocationInsList: getCustomerLocationInsListReducer,
    customerLocationInsUpdate: updateCustomerLocationInsReducer,
    employeeDepartmentList: getEmployeeDepartmentListReducer,
    employeeDesignationList: getEmployeeDesignationListReducer,
    invoiceTaxList: getInvoiceTaxListReducer,
    customerTimesheetProductList: getCustomerTimesheetProductListReducer,
    signUp: signUpReducer,
    customerTimesheetConsumableList: getCustomerTimesheetConsumableListReducer,
    auth: authReducer,
    quoteSourceList: quoteSourceListReducer,
  },
});

export default store;
