import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../model/app-state.model";
import { IError } from "../../../model/error.model";
import Request from "../../../utils/Request";
import { ISignUpResponse, ISignUpRequest, SignUpUrl } from "../constants";
import { showToast } from "../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../common/Toast/constants";

export interface SignUpState {
  isLoading: boolean;
  data: ISignUpResponse | null;
  error: IError | null;
}

const initialState: SignUpState = {
  isLoading: false,
  data: null,
  error: null,
};

// Create the async thunk for signUp
export const signUp = createAsyncThunk<
  ISignUpResponse,
  ISignUpRequest,
  { state: AppState; rejectValue: IError }
>("signUp", async (body: ISignUpRequest, { dispatch, rejectWithValue }) => {
  return new Promise<ISignUpResponse>((resolve, reject) => {
    try {
      const successFn = (data: ISignUpResponse, headers, isSuccess, status) =>
        resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      api.post(SignUpUrl.SIGNUP, body, "");
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        signUp.fulfilled,
        (state, action: PayloadAction<ISignUpResponse>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(signUp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = signUpSlice.actions;
export default signUpSlice.reducer;
