import { IError } from '../../model/error.model';


export enum LogoutUrl {
  LOGOUT = '/auth/logout',
}


export interface ILogoutResponse {
  message: string
}

export interface LogoutState {
  isLoading: boolean;
  data: string | null;
  error: IError | null;
}