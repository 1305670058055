import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../../../model/app-state.model';
import { IError } from '../../../../model/error.model';
import Request from '../../../../utils/Request';
import { IEmployeeResponse, IEmployeeRequest, EmployeeUrl, EmployeeState } from '../constants';
import { showToast } from '../../../../common/Toast/slice/toastSlice';
import { IToastType } from '../../../../common/Toast/constants';

const initialState: EmployeeState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const employeeUpdate = createAsyncThunk<
IEmployeeResponse,
IEmployeeRequest,
  { state: AppState, rejectValue: IError }
>('employeeUpdate', async (employeeRequest, { dispatch, rejectWithValue }) => {
    return new Promise<IEmployeeResponse>((resolve, reject) => {
        try {
          const successFn = (data: IEmployeeResponse, headers, isSuccess, status) =>
            resolve(data);
          const errorFn = (error: IError, headers, status) => {
            dispatch(
              showToast({
                message: error?.message,
                type: IToastType.ERROR,
              })
            );
            return reject(rejectWithValue(error));
          };
    
          const api = new Request(dispatch, successFn, errorFn, false);
          if(employeeRequest.id){
            const url = EmployeeUrl.BY_ID.replace(":employeeId", employeeRequest.id);
            api.put(url, employeeRequest);
          }else{
            api.post(EmployeeUrl.LIST, employeeRequest);
          }
        } catch (error) {
          return reject(error as IError);
        }
      });
});

// Create the slice
const employeeUpdateSlice = createSlice({
  name: 'employeeUpdate',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(employeeUpdate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(employeeUpdate.fulfilled, (state, action: PayloadAction<IEmployeeResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(employeeUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = employeeUpdateSlice.actions;
export default employeeUpdateSlice.reducer;