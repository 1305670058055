import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../../../model/app-state.model';
import { IError } from '../../../../model/error.model';
import Request from '../../../../utils/Request';
import { ICustomerResponse, ICustomerRequest, CustomerUrl, CustomerState } from '../constants';
import { showToast } from '../../../../common/Toast/slice/toastSlice';
import { IToastType } from '../../../../common/Toast/constants';

const initialState: CustomerState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const customerUpdate = createAsyncThunk<
ICustomerResponse,
ICustomerRequest,
  { state: AppState, rejectValue: IError }
>('customerUpdate', async (customerRequest, { dispatch, rejectWithValue }) => {
    return new Promise<ICustomerResponse>((resolve, reject) => {
        try {
          const successFn = (data: ICustomerResponse, headers, isSuccess, status) =>
            resolve(data);
          const errorFn = (error: IError, headers, status) => {
            dispatch(
              showToast({
                message: error?.message,
                type: IToastType.ERROR,
              })
            );
            return reject(rejectWithValue(error));
          };
    
          const api = new Request(dispatch, successFn, errorFn, false);
          if(customerRequest.id){
            const url = CustomerUrl.BY_ID.replace(":customerId", customerRequest.id);
            api.put(url, customerRequest);
          }else{
            api.post(CustomerUrl.LIST, customerRequest);
          }
        } catch (error) {
          return reject(error as IError);
        }
      });
});

// Create the slice
const customerUpdateSlice = createSlice({
  name: 'customerUpdate',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerUpdate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(customerUpdate.fulfilled, (state, action: PayloadAction<ICustomerResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(customerUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = customerUpdateSlice.actions;
export default customerUpdateSlice.reducer;