export enum RoutePath {
    DASHBOARD = '/:tenant/dashboard',
    USERS = '/:tenant/users',
    USER = '/:tenant/users/:userId',
    ROLES = '/:tenant/roles',
    ROLE = '/:tenant/roles/:roleId',
    EMPLOYEES = '/:tenant/employees',
    EMPLOYEE = '/:tenant/employees/:employeeId',
    CUSTOMERS = '/:tenant/customers',
    CUSTOMER = '/:tenant/customers/:customerId',
    PRODUCTS = '/:tenant/products',
    PRODUCT = '/:tenant/products/:productId',
    CONSUMABLES = '/:tenant/consumables',
    CONSUMABLE = '/:tenant/consumables/:consumableId',
    QUOTES = '/:tenant/quotes',
    QUOTE = '/:tenant/quotes/:quoteId',
    TIMESHEETS = '/:tenant/timesheets',
    TIMESHEET = '/:tenant/timesheets/:timesheetId',
    WORKORDERS = '/:tenant/workorders',
    WORKORDER = '/:tenant/workorders/:workOrderId',
    INVOICES = '/:tenant/invoices',
    INVOICE = '/:tenant/invoices/:invoiceId',
    LOGIN = '/login',
    DEFAULT = '/',
    SIGNUP = '/signup'
  }