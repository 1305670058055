import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import withLazyComponent from "./SuspenseWrapped";
import { lazy } from "react";
import { connect } from "react-redux";
import { AppState } from "../model/app-state.model";
import ProtectedLayout from "./ProtectedLayout";
import ProtectedWrapper from "./ProtectedWrapper";
import { RoutePath } from "./constants";

// AUTH COMPONENT IMPORT
const Login = withLazyComponent(lazy(() => import("../pages/Login")));

const SignUp = withLazyComponent(lazy(() => import("../pages/SignUp")));

const Employees = withLazyComponent(
  lazy(() => import("../pages/MasterData/Employee/employeeTable"))
);

const EmployeeView = withLazyComponent(
  lazy(() => import("../pages/MasterData/Employee/employeeView"))
);

const NotAuthorizedComponent = withLazyComponent(
  lazy(() => import("../common/ErrorPages/components/403-component"))
);

const Users = withLazyComponent(
  lazy(() => import("../pages/UsersAndRoles/users/userTable"))
);

const UserView = withLazyComponent(
  lazy(() => import("../pages/UsersAndRoles/users/userView"))
);


const Roles = withLazyComponent(
  lazy(() => import("../pages/UsersAndRoles/roles/roleTable"))
);

const RoleView = withLazyComponent(
  lazy(() => import("../pages/UsersAndRoles/roles/roleView"))
);

const Customers = withLazyComponent(
  lazy(() => import("../pages/MasterData/Customer/customerTable"))
);

const CustomerView = withLazyComponent(
  lazy(() => import("../pages/MasterData/Customer/customerView"))
);

const Products = withLazyComponent(
  lazy(() => import("../pages/MasterData/Product/productTable"))
);

const ProductView = withLazyComponent(
  lazy(() => import("../pages/MasterData/Product/productView"))
);

const Consumables = withLazyComponent(
  lazy(() => import("../pages/MasterData/Consumable/consumableTable"))
);

const ConsumableView = withLazyComponent(
  lazy(() => import("../pages/MasterData/Consumable/consumableView"))
);


const Quotes = withLazyComponent(
  lazy(() => import("../pages/Sales/Quote/quoteTable"))
);

const QuoteView = withLazyComponent(
  lazy(() => import("../pages/Sales/Quote/quoteView"))
);

const WorkOrders = withLazyComponent(
  lazy(() => import("../pages/Sales/WorkOrder/workOrderTable"))
);

const WorkOrderView = withLazyComponent(
  lazy(() => import("../pages/Sales/WorkOrder/workOrderView"))
);

const TimeSheets = withLazyComponent(
  lazy(() => import("../pages/Sales/TimeSheet/timeSheetTable"))
);

const TimeSheetView = withLazyComponent(
  lazy(() => import("../pages/Sales/TimeSheet/timeSheetView"))
);

const Invoices = withLazyComponent(
  lazy(() => import("../pages/Sales/Invoice/invoiceTable"))
);

const InvoiceView = withLazyComponent(
  lazy(() => import("../pages/Sales/Invoice/invoiceView"))
);


const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <ProtectedLayout path={RoutePath.DASHBOARD}></ProtectedLayout>,
    children: [
      {
        path: RoutePath.DASHBOARD,
        element: <Home />,
      },
      {
        path: RoutePath.EMPLOYEES,
        element: <ProtectedWrapper><Employees /></ProtectedWrapper>,
      },
      {
        path: RoutePath.EMPLOYEE,
        element: <ProtectedWrapper><EmployeeView /></ProtectedWrapper>,
      },
      {
        path: RoutePath.USERS,
        element: <ProtectedWrapper><Users/></ProtectedWrapper>
      },
      {
        path: RoutePath.USER,
        element: <ProtectedWrapper><UserView/></ProtectedWrapper>
      },
      {
        path: RoutePath.ROLES,
        element: <ProtectedWrapper><Roles/></ProtectedWrapper>
      },
      {
        path: RoutePath.ROLE,
        element: <ProtectedWrapper><RoleView/></ProtectedWrapper>
      },
      {
        path: RoutePath.CUSTOMERS,
        element: <ProtectedWrapper><Customers /></ProtectedWrapper>,
      },
      {
        path: RoutePath.CUSTOMER,
        element: <ProtectedWrapper><CustomerView /></ProtectedWrapper>,
      },
      {
        path: RoutePath.PRODUCTS,
        element: <ProtectedWrapper><Products /></ProtectedWrapper>,
      },
      {
        path: RoutePath.PRODUCT,
        element: <ProtectedWrapper><ProductView /></ProtectedWrapper>,
      },
      {
        path: RoutePath.CONSUMABLES,
        element: <ProtectedWrapper><Consumables /></ProtectedWrapper>,
      },
      {
        path: RoutePath.CONSUMABLE,
        element: <ProtectedWrapper><ConsumableView /></ProtectedWrapper>,
      },
      {
        path: RoutePath.QUOTES,
        element: <ProtectedWrapper><Quotes /></ProtectedWrapper>,
      },
      {
        path: RoutePath.QUOTE,
        element: <ProtectedWrapper><QuoteView /></ProtectedWrapper>,
      },
      {
        path: RoutePath.WORKORDERS,
        element: <ProtectedWrapper><WorkOrders /></ProtectedWrapper>,
      },
      {
        path: RoutePath.WORKORDER,
        element: <ProtectedWrapper><WorkOrderView /></ProtectedWrapper>,
      },
      {
        path: RoutePath.TIMESHEETS,
        element: <ProtectedWrapper><TimeSheets /></ProtectedWrapper>,
      },
      {
        path: RoutePath.TIMESHEET,
        element: <ProtectedWrapper><TimeSheetView /></ProtectedWrapper>,
      },
      {
        path: RoutePath.INVOICES,
        element: <ProtectedWrapper><Invoices /></ProtectedWrapper>,
      },
      {
        path: RoutePath.INVOICE,
        element: <ProtectedWrapper><InvoiceView /></ProtectedWrapper>,
      },
    ],
  },
  {
    path: RoutePath.LOGIN,
    element: <Login/>,
  },
  {
    path: RoutePath.SIGNUP,
    element: <SignUp/>,
  },
  {
    path: "403",
    element: <NotAuthorizedComponent/>
  },
  {
    path: "*",
    async action() {
      // We signout in a "resource route" that we can hit from a fetcher.Form
      return redirect(RoutePath.LOGIN);
    },
  },
]);

const RouteComponent = () => {
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
};

const mapStateToProps = (state: AppState) => {
  return state;
};

const mapDispatchToProps = () =>
  // dispatch: ThunkDispatch<AppState, void, AnyAction>
  {
    return {};
  };

export default connect(mapStateToProps, mapDispatchToProps)(RouteComponent);

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}
