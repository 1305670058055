import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../model/app-state.model";
import { IError } from "../../../../model/error.model";
import Request from "../../../../utils/Request";
import {
  IQuoteResponse,
  IQuoteRequest,
  QuoteUrl,
  QuoteState,
} from "../constants";
import { showToast } from "../../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../../common/Toast/constants";

const initialState: QuoteState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const quoteUpdate = createAsyncThunk<
  IQuoteResponse,
  IQuoteRequest,
  { state: AppState; rejectValue: IError }
>("quoteUpdate", async (quoteRequest, { dispatch, rejectWithValue }) => {
  return new Promise<IQuoteResponse>((resolve, reject) => {
    try {
      const successFn = (data: IQuoteResponse, headers, isSuccess, status) =>
        resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      if (quoteRequest.id) {
        const url = QuoteUrl.BY_ID.replace(":quoteId", quoteRequest.id);
        api.put(url, quoteRequest);
      } else {
        api.post(QuoteUrl.LIST, quoteRequest);
      }
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const quoteUpdateSlice = createSlice({
  name: "quoteUpdate",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(quoteUpdate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        quoteUpdate.fulfilled,
        (state, action: PayloadAction<IQuoteResponse>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(quoteUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = quoteUpdateSlice.actions;
export default quoteUpdateSlice.reducer;
