import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../model/app-state.model';
import {logout} from '../../pages/Logout/slice/logoutSlice';
import NavBarComponent from './components/NavBarComponent';

const mapStateToProps = (state: AppState) => {
  const {
    logout,
    currentUser,
    auth
  } = state;
  return {
    logoutState: logout,
    currentUser,
    authState: auth
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>
) => {
  return {
    logout: bindActionCreators(logout, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarComponent);
