export interface ILoginResponse {
    accessToken: string;
    type: string;
    id: number;
    email: string;
  }
  
  export interface IAccessToken {
    userId: string;
    firstName: string;
    lastName: string;
    iat: string;
    exp: string;
    allowedTenants: { code: string; name: string }[];
  }
  
  export interface ILoginRequest {
    username: string;
    password: string;
    orgCode?: string;
  }
  
  export enum LoginUrl {
    LOGIN = '/auth/login',
  }