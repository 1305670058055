import React from "react";
import { AuthContext } from "../common/AuthProvider/components/AuthProvider";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { RoutePath } from "./constants";

function ProtectedWrapper(props: any) {
  const { userDetails } = useContext(AuthContext);
  const location = useLocation();
  const pathname = location.pathname.split("/")[2].slice(0, -1);

  if (!userDetails.data && !userDetails.isLoading) {
    return <Navigate to={RoutePath.LOGIN} replace />;
  }
  if (userDetails.data) {
    if (
      !userDetails.data.grantedAuthorities.some((authority) =>
        authority.startsWith(`ROLE_${pathname.toUpperCase()}`)
      )
    ) {
      return <Navigate to="/403" replace />;
    }
  }
  return props.children;
}
export default ProtectedWrapper;
