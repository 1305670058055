import React from "react";
import {
  ShimmerWrapper,
  FlexWrapper,
  Box,
  LineWrapper,
  Line,
  Photo,
} from "./styles";

const ShimmerLoader = (): JSX.Element => {
  return (
    <ShimmerWrapper data-testid="shimmer-loader-test-id">
      <FlexWrapper className="flex flex-margin-right">
        <Box className="shine"></Box>
        <Box className="shine"></Box>
        <Photo className="shine"></Photo>
      </FlexWrapper>

      <LineWrapper>
        <Line className="shine mt-12"></Line>
        <Line className="shine mt-12"></Line>
        <Line className="shine mt-12"></Line>
        <Line className="shine mt-12"></Line>
      </LineWrapper>
      <Photo className="shine mt-16"></Photo>
      <Photo className="shine mt-16"></Photo>
      <br />
      <LineWrapper>
        <Line className="shine mt-12"></Line>
        <Line className="shine mt-12"></Line>
        <Line className="shine mt-12"></Line>
      </LineWrapper>
    </ShimmerWrapper>
  );
};

export default ShimmerLoader;
