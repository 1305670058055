import { IError } from "../../../model/error.model";
import { IPageableResponse } from "../../../model/page.model";
import { WorkOrderListPageable } from "../../Sales/WorkOrder/constants";
import { Organization } from "../../UsersAndRoles/constants";
import { ConsumableListPageable } from "../Consumable/constants";
import { ProductListPageable } from "../Product/constants";

export interface ICustomerResponse {
    id: number;
    customerId: string;
    firstName: string;
    lastName: string;
    contactPersonName: string;
    contactPersonPhone: string;
    email: string;
    phone: string;
    fax?: string;
    country: string;
    city: string;
    addressLineOne: string;
    addressLineTwo: string;
    postalCode?: number;
    creditTerms: number;
    vatRefNo?: number;
    updatedAt: string;
    Organization: Organization;
    location: ICustomerLocationInsResponse;
    customerContacts: ICustomerContact[];
}

export interface ICustomerLocationInsResponse {
    id: string,
    organization: number,
    name: string
}

export interface ICustomerContact {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    isActive: boolean;
}

export interface CustomerLocationInsListPageable extends IPageableResponse {
    content: ICustomerLocationInsResponse[];
}

export interface CustomerLocationInsListState {
    isLoading: boolean;
    data: CustomerLocationInsListPageable | null;
    error: IError | null;
}

export interface CustomerLocationInsState {
    isLoading: boolean;
    data: ICustomerLocationInsResponse | null;
    error: IError | null;
}

export interface CustomerListState {
    isLoading: boolean;
    data: CustomerListPageable | null;
    error: IError | null;
}

export interface CustomerListPageable extends IPageableResponse {
    content: ICustomerResponse[];
}

export interface ITimesheetProductResponse{
    workOrderTitle: string;
    totalQuantity: number;
    workOrderItemId: string;
    productActivity: string;
    productPrice: string;
}

export interface ITimesheetConsumableResponse{
    workOrderTitle: string;
    totalQuantity: number;
    workOrderItemConsumableId: string;
    consumableName: string;
    consumablePrice: string;
}

export interface TimesheetProductListPageable extends IPageableResponse{
    content: ITimesheetProductResponse[];
}

export interface TimesheetConsumableListPageable extends IPageableResponse{
    content: ITimesheetConsumableResponse[];
}

export interface ICustomerRequest {
    id: string;
}

export interface ICustomerLocationRequest {
    id?: string;
    name: string;
}

export interface CustomerState {
    isLoading: boolean;
    data: ICustomerResponse | null;
    error: IError | null;
}

export interface CustomerProductListState {
    isLoading: boolean;
    data: ProductListPageable | null;
    error: IError | null;
}

export interface CustomerConsumableListState {
    isLoading: boolean;
    data: ConsumableListPageable | null;
    error: IError | null;
}


export interface CustomerWorkOrderListState {
    isLoading: boolean;
    data: WorkOrderListPageable | null;
    error: IError | null;
}

export interface CustomerTimesheetProductListState {
    isLoading: boolean;
    data: TimesheetProductListPageable | null;
    error: IError | null;
}

export interface CustomerTimesheetConsumableListState {
    isLoading: boolean;
    data: TimesheetConsumableListPageable | null;
    error: IError | null;
}

export enum CustomerUrl {
    LIST = '/customers',
    BY_ID = '/customers/:customerId',
    PRODUCTS = '/customers/:customerId/products',
    CONSUMABLES = '/customers/:customerId/consumables',
    WORKORDERS = '/customers/:customerId/workorders',
    CUSTOMER_LOCATION_INS_LIST = '/customers/locations',
    TIMESHEET_PRODUCTS = '/customers/:customerId/timesheets/products',
    TIMESHEET_CONSUMABLES = '/customers/:customerId/timesheets/consumables',
    CUSTOMER_LOCATION_INS_BY_ID = '/customers/locations/:locationId',
}