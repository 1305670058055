import { IAuditable } from "../../../model/auditable.model";
import { IError } from "../../../model/error.model";
import { IPageableResponse } from "../../../model/page.model";
import { ICustomerResponse } from "../../MasterData/Customer/constants";
import { Organization } from "../../UsersAndRoles/constants";
import { IWorkOrderItem, IWorkOrderItemConsumable } from "../WorkOrder/constants";

export interface ITimeSheetResponse extends IAuditable {
  id: string;
  customer: ICustomerResponse;
  organization: Organization;
  version: number;
  notes: string;
  status: TimeSheetStatus;
  dow: string;
  jobNumber: string;
  jobLocation: string;
  timesheetNumber: string;
  isBillable: boolean;
  isActive: boolean;
  code: string;
  timesheetProductItem: ITimesheetProductItem;
  timesheetConsumableItems: ITimesheetConsumableItem[];
}

export interface ITimesheetProductItem extends IAuditable{
  id: string,
  isBillable: boolean;
  isActive: boolean;
  quantity: number;
  workOrderItem: IWorkOrderItem
  remarks?: string;
}

export interface ITimesheetConsumableItem extends IAuditable{
  id: string,
  isBillable: boolean;
  isActive: boolean;
  quantity: number;
  workOrderItemConsumable: IWorkOrderItemConsumable;
  remarks?: string;
}

export enum TimeSheetStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  BILLED = "BILLED",
  SERVICE_BILLED = "SERVICE_BILLED",
  CONSUMABLE_BILLED = "CONSUMABLE_BILLED",
}
export interface TimeSheetListState {
  isLoading: boolean;
  data: TimeSheetListPageable | null;
  error: IError | null;
}

export interface TimeSheetListPageable extends IPageableResponse {
  content: ITimeSheetResponse[];
}

export interface ITimeSheetRequest {
  id: string;
}

export interface TimeSheetState {
  isLoading: boolean;
  data: ITimeSheetResponse | null;
  error: IError | null;
}

export enum TimeSheetUrl {
  LIST = "/timesheets",
  BY_ID = "/timesheets/:timesheetId",
}
