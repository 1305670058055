export enum IToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
export enum IToastPosition {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}
export interface IToast {
  type: IToastType;
  message: string | undefined;
  details?: string;
  position?: IToastPosition;
  timeout?: number;
}
