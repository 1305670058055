import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../../model/app-state.model';
import { IError } from '../../../model/error.model';
import Request from '../../../utils/Request';
import { IRoleResponse, IRoleRequest, RoleUrl, RoleState } from '../constants';
import { showToast } from '../../../common/Toast/slice/toastSlice';
import { IToastType } from '../../../common/Toast/constants';

const initialState: RoleState = {
  isLoading: false,
  data: null,
  error: null,
};

// Define the async thunk
export const roleUpdate = createAsyncThunk<
IRoleResponse,
IRoleRequest,
  { state: AppState, rejectValue: IError }
>('roleUpdate', async (request, { dispatch, rejectWithValue }) => {
    return new Promise<IRoleResponse>((resolve, reject) => {
        try {
          const successFn = (data: IRoleResponse, headers, isSuccess, status) =>
            resolve(data);
          const errorFn = (error: IError, headers, status) => {
            dispatch(
              showToast({
                message: error?.message,
                type: IToastType.ERROR,
              })
            );
            return reject(rejectWithValue(error));
          };
    
          const api = new Request(dispatch, successFn, errorFn, false);
          if(request.id){
            const url = RoleUrl.BY_ID.replace(":roleId", request.id);
            api.put(url, request);
          }else{
            api.post(RoleUrl.LIST, request);
          }
        } catch (error) {
          return reject(error as IError);
        }
      });
});

// Create the slice
const roleUpdateSlice = createSlice({
  name: 'roleUpdate',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(roleUpdate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(roleUpdate.fulfilled, (state, action: PayloadAction<IRoleResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(roleUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = roleUpdateSlice.actions;
export default roleUpdateSlice.reducer;