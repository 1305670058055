import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body,
#root {
  height: 100vh;
}

body {
  background: var(--white-color);
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e5e5e5;
  font-size: 14px;
  color: var(--dark-grey-color);
  line-height: 20px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

hr {
    border-top: 2px solid #EBEBEB;
    margin: 20px 0;
    &.border-thin {
      border-width: 1px 0 0 0;
    }
}


.flex {
    display: flex;
    &.flex-wrap {
      flex-wrap: wrap;
    }
    &.flex-column {
      flex-direction: column;
    }
    &.flex-align-center {
      align-items: center;
    }
    &.flex-justify-center {
      justify-content: center;
    }
    &.flex-justify-space-between {
      justify-content: space-between;
    }
    &.flex-justify-space-around {
      justify-content: space-around;
    }
    &.flex-justify-end {
      justify-content: flex-end;
    }
  }

`;

export default GlobalStyle;
