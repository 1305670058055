import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../model/app-state.model";
import { IError } from "../../../model/error.model";
import Request from "../../../utils/Request";
import { ILoginResponse, ILoginRequest, LoginUrl } from "../constants";
import { showToast } from "../../../common/Toast/slice/toastSlice";
import { IToastType } from "../../../common/Toast/constants";

export interface LoginState {
  isLoading: boolean;
  data: ILoginResponse | null;
  error: IError | null;
}

const initialState: LoginState = {
  isLoading: false,
  data: null,
  error: null,
};

// // Define the async thunk
// export const login = createAsyncThunk<
//   ILoginResponse,
//   ILoginRequest,
//   { state: AppState; rejectValue: IError }
// >("login", async (body, { dispatch, rejectWithValue }) => {
//   try {
//     const api = new Request(
//       dispatch,
//       (data, headers, isSuccess, status) => {
//         if (isSuccess) {
//           return data;
//         }
//       },
//       (error, headers, status) => {
//         dispatch(
//             showToast({
//               message: error?.message,
//               type: IToastType.ERROR
//             })
//           );
//         return rejectWithValue(error as IError);
//       },
//       true
//     );
//     const response = await api.post(LoginUrl.LOGIN, body);
//     return response?.data || {};
//   } catch (error) {
//     return rejectWithValue(error as IError);
//   }
// });

// Create the async thunk for login
export const login = createAsyncThunk<
  ILoginResponse,
  ILoginRequest,
  { state: AppState; rejectValue: IError }
>("login", async (body: ILoginRequest, { dispatch, rejectWithValue }) => {
  return new Promise<ILoginResponse>((resolve, reject) => {
    try {
      const successFn = (data: ILoginResponse, headers, isSuccess, status) =>
        resolve(data);
      const errorFn = (error: IError, headers, status) => {
        dispatch(
          showToast({
            message: error?.message,
            type: IToastType.ERROR,
          })
        );
        return reject(rejectWithValue(error));
      };

      const api = new Request(dispatch, successFn, errorFn, false);
      api.post(LoginUrl.LOGIN, body, "");
    } catch (error) {
      return reject(error as IError);
    }
  });
});

// Create the slice
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<ILoginResponse>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as IError;
      });
  },
});

export const { reset } = loginSlice.actions;
export default loginSlice.reducer;
